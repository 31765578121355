import axios from 'axios';

export const getMonthsFunnel = ({ firstMonth, secondMonth }) =>
  axios.get(`/dashboards/summary/month/?month=${firstMonth}&month=${secondMonth}`);

export const getBackofficeMonthsFunnel = ({ firstMonth, secondMonth, partner, viewMode, partnerId }) => {
  const user = partnerId ? `&partner=${partnerId}` : ``;
  return axios.get(`/backoffice/dashboards/summary/month/?month=${firstMonth}&month=${secondMonth}${user}`, {
    params: { partner, view_mode: viewMode },
  });
};

export const getEquallyMonthsFunnel = ({ firstMonth, secondMonth }) =>
  axios.get(`/dashboards/summary_compare/?first_month=${firstMonth}&second_month=${secondMonth}`);

export const getBackofficeEquallyMonthsFunnel = ({ firstMonth, secondMonth, partner, viewMode, partnerId }) => {
  const user = partnerId ? `&partner=${partnerId}` : ``;
  return axios.get(
    `/backoffice/dashboards/summary_compare/?first_month=${firstMonth}&second_month=${secondMonth}${user}`,
    { params: { partner, view_mode: viewMode } }
  );
};

export const getBrokerTarget = mode =>
  axios.get('/backoffice/dashboards/broker_target/', { params: { view_mode: mode } });

export const getPartnerTarget = partnerId => {
  if (partnerId) {
    return axios.get(`/backoffice/dashboards/partner_target/${partnerId}/`);
  }
  return axios.get('/dashboards/partner_target/');
};

export const putBrokerTarget = (data, mode) =>
  axios
    .put('/backoffice/dashboards/broker_target/', data, { params: { view_mode: mode } })
    .then(response => response.data);

export const putPartnerTarget = data => axios.put('/dashboards/partner_target/', data).then(response => response.data);

export const getDailyFunnels = ({ startDate, endDate }) =>
  axios.get(`/dashboards/summary/day/?from=${startDate}&to=${endDate}`);

// eslint-disable-next-line camelcase
export const getBackofficeDailyFunnels = ({ startDate, endDate, partner, viewMode, partnerId }) => {
  const user = partnerId ? `&partner=${partnerId}` : ``;
  return axios.get(`/backoffice/dashboards/summary/day/?from=${startDate}&to=${endDate}${user}`, {
    params: { partner, view_mode: viewMode },
  });
};

export const getBackofficeEvents = (partnerId, mode) =>
  axios.get(`/backoffice/dashboards/events/${partnerId ? `?user=${partnerId}` : ''}`, {
    params: { page: 1, page_size: 50, view_mode: mode },
  });

export const getTotals = () => axios.get('/dashboards/totals?scope=partner');
export const getManagerTotals = () => axios.get('/dashboards/totals?scope=manager');

export const getPartnershipEvents = () => axios.get('/dashboards/events/', { params: { page: 1, page_size: 50 } });
